import React from 'react'
import { graphql } from 'gatsby'
import Articles from '../components/templates/articles/'

const ArticlesPage = props => {
  const posts = props.data.allMarkdownRemark
  const tags = []

  const published = []
  posts.edges.map(({ node }) => ( published.push(node)))

  published.map(( node ) => (
    node.frontmatter.tags.map(tag => {
      if (tags.indexOf(tag) === -1) {
        tags.push(tag)
      }
  })))

  return (
    <Articles posts={published} context={props.pageContext} tags={tags} />
  )
}

export default ArticlesPage

export const ArticlesQuery = graphql`
  query ArticlesQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: {frontmatter: {publish: {ne: false}}},
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM Do YYYY")
            title
            tags
            url
            publish
          }
        }
      }
    }
  }
`
